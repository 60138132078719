/** @jsx jsx */
import { jsx, Flex, Box, Styled } from "theme-ui"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Container from "./container"
import Link from "./link"
import { Button } from "@theme-ui/components"

export default ({ title, image, ctaLink, ctaTitle, locale }) => {
  return (
    <Flex
      sx={{
        position: "relative",
        height: ["60vh", "75vh"],
        minHeight: [360, null, 480],
        maxHeight: 640,
      }}
    >
      <Img
        fluid={image.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        alt={image.description}
        sx={{
          width: "100%",
          pb: 0,
          bg: "primary",
          img: {
            mixBlendMode: "screen",
            filter: "grayscale(1)",
          },
        }}
      ></Img>
      <Flex
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          alignItems: "flex-end",
          py: [4, null, 5],
          lineHeight: "heading",
        }}
      >
        <Container>
          {title.childMdx ? (
            <Box
              sx={{
                maxWidth: 900,
                h1: { m: 0 },
                em: { fontStyle: "normal", color: "primaryDark" },
              }}
            >
              <MDXRenderer>{title.childMdx.body}</MDXRenderer>
            </Box>
          ) : (
            <Styled.h1>{title}</Styled.h1>
          )}
          {ctaTitle && ctaLink && (
            <Button
              as={Link}
              to={locale === "en" ? `/en${ctaLink}` : ctaLink}
              sx={{ mt: [3, null, 4] }}
            >
              {ctaTitle}
            </Button>
          )}
        </Container>
      </Flex>
    </Flex>
  )
}
