/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from "../components/container"
import Spacer from "../components/spacer"
import Hero from "../components/hero"
import Contact from "../components/contact"

export default ({ data, pageContext }) => {
  const {
    title,
    node_locale,
    image,
    body,
    author,
    publishDate,
  } = data.contentfulBlogPost

  const locale = pageContext.locale
  const published = locale === "en" ? "Published" : "Julkaistu"

  return (
    <Layout locale={locale}>
      <SEO title={title} description={""} />
      <Hero image={image} title={title} />
      <Spacer size={[4, null, 5]} />
      <Container>
        <Flex
          sx={{
            flexWrap: ["wrap", null, null, "nowrap"],
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "auto", maxWidth: 800 }}>
            <MDXRenderer>{body.childMdx.body}</MDXRenderer>
            <Box sx={{ color: "#808080" }}>
              {published} {publishDate}
            </Box>
          </Box>
          <Box
            sx={{
              flex: ["auto", null, null, "none"],
              width: ["auto", null, null, 360],
              maxWidth: 480,
              ml: [0, null, null, 4],
            }}
          >
            <Spacer size={[3, 4, null, 0]} />
            <Contact contact={author} mini />
          </Box>
        </Flex>
        <Spacer size={[4, null, 5]} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      node_locale
      publishDate(formatString: "D.M.YYYY")
      body {
        childMdx {
          id
          body
        }
      }
      image {
        description
        fluid {
          sizes
          base64
          aspectRatio
          src
          srcSet
        }
      }
      author {
        name
        phoneNumber
        jobTitle
        email
        summary
        linkedInProfile
        image {
          description
          fluid {
            sizes
            base64
            aspectRatio
            src
            srcSet
          }
        }
      }
    }
  }
`
