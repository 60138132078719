/** @jsx jsx */
import { jsx, Flex, Box, Styled } from "theme-ui"
import React from "react"
import Img from "gatsby-image"

export default props => {
  const {
    name,
    phoneNumber,
    jobTitle,
    email,
    image,
    summary,
    linkedInProfile,
  } = props.contact
  return (
    <Flex
      sx={{
        textAlign: props.mini ? "left" : ["left", null, "center"],
        flexDirection: props.mini ? "row" : ["row", null, "column"],
        alignItems: props.mini ? "flex-start" : ["flex-start", null, "center"],
        mb: 3,
      }}
    >
      <Box
        sx={{
          flex: "none",
          maxWidth: props.mini ? 120 : [140, 240],
          width: "100%",
          mx: "auto",
          pr: props.mini ? 3 : [3, 4, 0],
        }}
      >
        <Img
          fluid={image.fluid}
          sx={{
            width: "100%",
            height: 0,
            pb: "100%",
            borderRadius: "50%",
            img: { objectPosition: "top center !important" },
          }}
          alt={image.description}
        />
      </Box>
      <Box sx={{ flex: 1, my: props.mini ? 0 : [0, 0, 3] }}>
        <Styled.h3
          sx={{
            fontSize: props.mini ? [2, 2, 2] : [3, null, 4],
            my: 1,
            ":not(:first-child)": { mt: 0 },
          }}
        >
          {name}
        </Styled.h3>
        <Box sx={{ fontSize: props.mini ? [2, 2, 2] : "inherit" }}>
          {jobTitle && jobTitle}
          <br />
          {phoneNumber && (
            <>
              <Styled.a href={`tel:${phoneNumber}`}>{phoneNumber}</Styled.a>
              <br />
            </>
          )}
          {email && <Styled.a href={`mailto:${email}`}>{email}</Styled.a>}
        </Box>
        {summary && (
          <Box sx={{ my: 2, fontSize: 2, lineHeight: "1.375" }}>{summary}</Box>
        )}
        {linkedInProfile && (
          <Styled.a href={linkedInProfile} sx={{ fontSize: 2 }}>
            <Box
              as="svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              sx={{
                dipslay: "block",
                width: 16,
                height: 16,
                fill: "primaryDark",
              }}
              title="LinkedIn"
            >
              <rect x="2" y="8.5" width="5" height="14" rx=".5" ry=".5" />
              <ellipse cx="4.48" cy="4" rx="2.48" ry="2.5" />
              <path d="M18.5 22.5h3a.5.5 0 00.5-.5v-8.4C22 9.83 19.87 8 16.89 8a4.21 4.21 0 00-3.17 1.27A.41.41 0 0113 9a.5.5 0 00-.5-.5h-3A.5.5 0 009 9v13a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-7.5a2.5 2.5 0 015 0V22a.5.5 0 00.5.5z" />
            </Box>
          </Styled.a>
        )}
      </Box>
    </Flex>
  )
}
